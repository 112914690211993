<template>
  <contact-card-wrapper>
    <div class="contact-card">
      <div class="user-info">
        <ul>
          <li>
            <sdFeatherIcons type="user" size="16" />
            {{ user.firstName + ' ' + user.lastName }}
          </li>
          <li>
            <sdFeatherIcons type="mail" size="16" />
            {{ user.email }}
          </li>
          <li v-if="user.type !== 'BackOfficeUser'">
            <sdFeatherIcons type="help-circle" size="16" />
            {{ $t(`models.user.attributes.userTypes.${user.userType}`) }}
          </li>
        </ul>
      </div>
    </div>
  </contact-card-wrapper>
</template>

<script>
import {computed, defineComponent} from 'vue';
import {ContactCardWrapper} from '@/components/shared/cards/style';
import {useStore} from 'vuex';

export default defineComponent({
  name: 'ViewUserInfo',
  components: {ContactCardWrapper},
  setup() {
    const {getters} = useStore();
    const user = computed(() => getters.user);

    return {
      user
    };
  }

});
</script>

<style lang="scss" scoped>
.contact-card .user-info {
    margin-top: 0px !important;
    border-top: 0px !important;
    padding-top: 10px !important;
}
</style>

<template>
  <app-form
    :fields="fields"
    :loading="loading"
    @submitted="handleSubmit"
    @forceUpdated="handleForceUpdate"
    :cancelButton="cancelButton"
    @cancel="$emit('closeEdit')"
  />
</template>

<script>
import {
  computed, defineComponent, reactive, ref, watch
} from 'vue';
import {required, email} from '@/helpers/validationRules';
import {useStore} from 'vuex';
import _ from 'lodash';
import {useI18n} from 'vue-i18n';

export default defineComponent({
  name: 'EditUser',
  emits: ['closeEdit'],
  setup(props, context) {
    const {
      getters,
      dispatch,
      state
    } = useStore();

    const {t} = useI18n();

    const fields = reactive({
      firstName: {
        type: 'text',
        label: 'models.user.attributes.firstName',
        fieldValue: computed(() => getters.user?.firstName),
        rules: [required],
        styles: {md: 12}
      },
      lastName: {
        type: 'text',
        label: 'models.user.attributes.lastName',
        fieldValue: computed(() => getters.user?.lastName),
        rules: [required],
        styles: {md: 12}
      },
      email: {
        type: 'text',
        label: 'models.user.attributes.email',
        fieldValue: computed(() => getters.user?.email),
        rules: [required, email],
        disabled: computed(() => !_.isEmpty(getters.user) && getters.user._jv.id !== state.session.currentUser._jv?.id),
        styles: {xs: 24}
      },
      userType: {
        type: 'select',
        fieldValue: computed(() => getters.user?.userType),
        label: 'models.user.attributes.userType',
        placeholder: 'models.user.attributes.userType',
        options: [
          {
            value: 'primary_user',
            label: t('models.user.attributes.userTypes.primary_user')
          },
          {
            value: 'secondary_user',
            label: t('models.user.attributes.userTypes.secondary_user')
          }
        ],
        styles: {md: 24},
        disabled: computed(() => !_.isEmpty(getters.user) && getters.user._jv.id === state.session.currentUser._jv?.id)
      }
    });

    const cancelButton = {
      visible: true,
      label: 'actions.cancel',
      size: 'small',
      type: 'light',
      outlined: true
    };

    const loading = ref(false);
    const handleSubmit = async (data) => {
      loading.value = true;
      const status = user.value._jv.id === state.session.currentUser._jv.id ? await dispatch('updateAccount', data) :
        await dispatch('updateUser', {
          form: data,
          id: user.value._jv.id
        });
      loading.value = false;
      if (status === 200) {
        setFields();
        context.emit('closeEdit');
      }
    };

    const handleForceUpdate = (keys) => _.forEach(keys, (key) => fields[key]['forceUpdate'] = false);

    const user = computed(() => getters.user);
    watch(user, (data) => {
      if (!_.isEmpty(data)) setFields();
    });

    const setFields = () => {
      _.forEach(['email', 'firstName', 'lastName', 'userType'], (key) => fields[key]['forceUpdate'] = true);
    };

    return {
      user,
      fields,
      loading,
      cancelButton,
      handleSubmit,
      handleForceUpdate
    };
  }
});
</script>

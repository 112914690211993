<template>
  <sdCards
    :title="editAction ? $t('views.userProfile.editProfile') : $t('views.userProfile.title')"
  >
    <template #button>
      <sdButton
        v-if="$can('edit', user) && !editAction"
        shape="circle"
        class="btn-icon"
        type="default"
        @click="editAction = true"
        :tooltip="$t('actions.edit')"
      >
        <sdFeatherIcons type="edit" size="16" />
      </sdButton>
    </template>

    <a-row type="flex" justify="center">
      <a-col :lg="20" :sm="22" :xs="24">
        <edit-user v-if="editAction" @closeEdit="editAction = false" />
        <view-user v-else />
      </a-col>
    </a-row>
  </sdCards>
</template>

<script>
import {
  computed, defineComponent, ref
} from 'vue';
import {useStore} from 'vuex';
import EditUser from '@/components/users/EditUser';
import ViewUser from '@/components/users/ViewUser';

export default defineComponent({
  components: {ViewUser, EditUser},
  setup() {
    const editAction = ref(false);
    const {getters} = useStore();
    const user = computed(() => getters.user);

    return {
      editAction,
      user
    };
  }
});
</script>
